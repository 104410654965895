<template>
    <v-container>
            <v-row justify="center" class="py-5">
                <h1 class="display-1 font-weight-light">Reports</h1>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="px-10 pb-5">
                        <v-card-text class="title text-center">Current item reports</v-card-text>
                        <v-divider class="mx-5 mb-10"></v-divider>
                        <v-list>
                            <v-list-item><router-link to="/report/view?id=all-clients">All clients</router-link></v-list-item>
                            <v-list-item><router-link to="/report/view?id=all-users">All users</router-link></v-list-item>
                            <v-list-item><router-link to="/report/view?id=user-publickey-list">All user public keys</router-link></v-list-item>
                            <v-list-item><router-link to="/report/view?id=all-accounts">All accounts</router-link></v-list-item>
                            <v-list-item><router-link to="/report/view?id=all-realms">All realms</router-link></v-list-item>
                            <v-list-item><router-link to="/report/view?id=all-sessions">All sessions</router-link></v-list-item>
                            <v-list-item><router-link to="/report/view?id=all-tables">All tables</router-link></v-list-item>
                        </v-list>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <!-- nudge items indicate someone might need help getting started in using the product -->
                        <v-card-text class="title text-center">Nudge item reports</v-card-text>
                        <v-divider class="mx-5 mb-10"></v-divider>
                        <v-list>
                            <v-list-item><router-link to="/report/view?id=account-type-enterprise-without-realm-list">Enterprise accounts without realms</router-link></v-list-item>
                            <v-list-item><router-link to="/report/view?id=client-with-empty-user-list">Clients not linked to users</router-link></v-list-item>
                        </v-list>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <!-- junk items are not recoverable: a public key without a user can never be used, an account without a user can never be used -->
                        <v-card-text class="title text-center">Junk item reports</v-card-text>
                        <v-divider class="mx-5 mb-10"></v-divider>
                        <v-list>
                            <v-list-item><router-link to="/report/view?id=user-publickey-with-nonexistent-user-list">User public keys with non-existent user</router-link></v-list-item>
                            <v-list-item><router-link to="/report/view?id=account-junk-list">Accounts without users</router-link></v-list-item>
                            <v-list-item><router-link to="/report/view?id=link-realm-user-with-nonexistent-user-list">Realm user links with non-existent users</router-link></v-list-item>
                            <v-list-item><router-link to="/report/view?id=client-with-nonexistent-user-list">Clients with non-existent users</router-link></v-list-item>
                            <v-list-item><router-link to="/report/view?id=user-email-with-nonexistent-user-list">Email addresses with non-existent users</router-link></v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
    </v-container>
</template>
<script>

export default {
    components: {
    },
};
</script>
